var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.mobile ? '' : 'flex-shrink-0 ml-0'},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-0 ml-0",attrs:{"text":!_vm.mobile,"small":"","fab":"","color":"grey--text","aria-label":"Add Torrent"},on:{"click":function($event){return _vm.addModal('AddModal')}}},on),[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v(" "+_vm._s(_vm.mdiPlus)+" ")])],1)]}}])},[_c('span',[_vm._v(" Add Torrent")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-0 ml-0",attrs:{"small":"","fab":"","text":!_vm.mobile,"aria-label":"Resume Selected Torrents"},on:{"click":_vm.resumeTorrents}},on),[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v(" "+_vm._s(_vm.mdiPlay)+" ")])],1)]}}])},[_c('span',[_vm._v("Resume Selected Torrents")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-0 ml-0",attrs:{"small":"","fab":"","text":!_vm.mobile,"aria-label":"Pause Selected Torrents"},on:{"click":_vm.pauseTorrents}},on),[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v(" "+_vm._s(_vm.mdiPause)+" ")])],1)]}}])},[_c('span',[_vm._v("Pause Selected Torrents")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-0 ml-0",attrs:{"small":"","fab":"","text":!_vm.mobile,"aria-label":"Remove Selected Torrents"},on:{"click":_vm.removeTorrents}},on),[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v(" "+_vm._s(_vm.mdiDelete)+" ")])],1)]}}])},[_c('span',[_vm._v("Remove Selected Torrents")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-0 ml-0",attrs:{"text":!_vm.mobile,"small":"","fab":"","color":"grey--text","aria-label":"Search New Torrent"},on:{"click":function($event){return _vm.addModal('SearchModal')}}},on),[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v(" "+_vm._s(_vm.mdiSearchWeb)+" ")])],1)]}}])},[_c('span',[_vm._v("Search new Torrent")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-0 ml-0",attrs:{"small":"","fab":"","text":!_vm.mobile,"aria-label":"Open Settings"},on:{"click":function($event){return _vm.addModal('SettingsModal')}}},on),[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v(" "+_vm._s(_vm.mdiCog)+" ")])],1)]}}])},[_c('span',[_vm._v("Open Settings")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }